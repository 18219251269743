<template>
    <van-sticky class="sticky">
        <header class="header">
            <div class="container">
                <img class="logo" @click="toPage('/')" src="@/assets/images/logo1.png" />
                <div class="menu">
                    <div :class="$route.name === 'index' ? 'active' : ''">
                        <a href="/">首页</a>
                    </div>
                    <!-- <div :class="$route.name === 'product' ? 'active' : ''">
                        <a href="/product">课程中心</a>
                    </div> -->
                    <div :class="$route.name === 'paper' ? 'active' : ''">
                        <a href="/paper">题库</a>
                    </div>
                </div>
                <div class="user" v-if="userInfo">
                    <div class="user-course" @click="toPage('/course')">我的课程</div>
                    <van-popover v-model="isShowPopover" trigger="click" :actions="actions" @select="onSelect">
                        <template #reference>
                            <img class="avatar" :src="userInfoAvatar" />
                        </template>
                    </van-popover>
                </div>
                <div class="user-login" v-else>
                    <div @click="onShowLogin">登录</div>
                </div>
            </div>
        </header>
        <header class="mobile-header">
            <van-icon @click="isShowMobileMenuPopup = true" name="wap-nav" />
            <h2 class="ov-ellipsis">{{ title }}</h2>
            <div class="user" v-if="userInfo">
                <van-popover
                    placement="bottom-end"
                    v-model="isShowMobilePopover"
                    trigger="click"
                    :actions="actionsM"
                    @select="onSelect"
                >
                    <template #reference>
                        <img class="avatar" :src="userInfoAvatar" />
                    </template>
                </van-popover>
            </div>
            <div class="user-login" v-else>
                <div @click="onShowLogin">登录</div>
            </div>
        </header>

        <van-popup class="menu-popup" v-model="isShowMobileMenuPopup" position="top" :duration="0.4">
            <div @click="isShowMobileMenuPopup = false">
                <a href="/">首页</a>
                <!-- <a href="/product">课程中心</a> -->
                <a href="/paper">题库</a>
            </div>
        </van-popup>
    </van-sticky>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            isShowPopover: false,
            actions: [
                { text: '个人信息', path: '/account/userInfo' },
                { text: '我的订单', path: '/account/order' },
                { text: '退出登录', path: '' },
            ],
            isShowMobilePopover: false,
            actionsM: [
                { text: '我的课程', path: '/course' },
                { text: '个人信息', path: '/account/userInfo' },
                { text: '我的订单', path: '/account/order' },
                { text: '退出登录', path: '' },
            ],

            isShowMobileMenuPopup: false,
        }
    },
    computed: {
        ...mapGetters(['userInfo', 'deviceInfo']),
        title: function () {
            const titleMap = {
                index: '师业有成',
                product: '课程中心',
                course: '我的课程',
                'productDetails-packageNo': '课程详情',
                'orderVerify-packageNo': '确认订单信息',
                'account-userInfo': '个人中心',
                'account-order': '我的订单',
                'teacher-teacherNo': '师资力量',
            }
            const { title } = this.$route.query
            if (title) {
                return title
            } else {
                return titleMap[this.$route.name]
            }
        },
        userInfoAvatar: function () {
            if (this.userInfo) {
                return this.userInfo.avatar
            }
            return 'https://res.jiaoshi.fun/resource/20231030/7fbe4be1cc394431affd370d15a42d25.png'
        },
    },
    methods: {
        onShowLogin() {
            this.$store.dispatch('user/showLoginDialog')
        },
        onSelect(path) {
            if (path.path) {
                this.$router.push(path.path)
            } else {
                this.$store.dispatch('user/logout')
            }
        },
        toPage(path) {
            if (this.$route.path !== path) {
                this.$router.push(path)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.sticky {
    position: relative;
    z-index: 100;
}
.header {
    height: 64px;
    background: #ffffff;
    z-index: 1;
    box-shadow: 0px 2px 8px 0px rgba(107, 102, 99, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        min-width: $center-width;
        display: flex;
        align-items: center;
        padding: 0 16px;
        box-sizing: border-box;
        position: relative;
        height: 100%;
        .logo {
            height: 42px;
            cursor: pointer;
            background: none;
        }
        .menu {
            display: flex;
            height: 100%;
            margin-left: 30px;
            > div {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-size: 15px;
                color: #33312f;
                line-height: 16px;
                letter-spacing: 1px;
                flex-grow: 1;
                > a {
                    padding: 0 24px;
                    display: block;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    color: #33312f;
                }
            }
            .active {
                color: $theme-color;
                font-weight: 700;
                position: relative;
                a {
                    color: $theme-color;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    width: 28px;
                    height: 2px;
                    background: $theme-color;
                    transform: translateX(-50%);
                }
            }
        }
        .user {
            position: absolute;
            height: 100%;
            right: 16px;
            display: flex;
            align-items: center;
            .user-course {
                cursor: pointer;
                margin-right: 20px;
            }
            .avatar {
                cursor: pointer;
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
        }
        .user-login {
            cursor: pointer;
            position: absolute;
            height: 100%;
            right: 16px;
            display: flex;
            align-items: center;
            height: 14px;
            font-size: 14px;
            color: $theme-color;
            line-height: 14px;
            font-weight: 700;
            .br {
                margin: 0 8px;
                width: 1.5px;
                height: 15px;
                background: $theme-color;
            }
        }
    }
}

.mobile-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    padding: 0 16px;
    background: $theme-color;
    color: #ffffff;
    .van-icon-wap-nav {
        font-size: 28px;
    }
    h2 {
        font-size: 20px;
        flex-grow: 1;
        text-align: center;
        margin: 0 20px;
    }
    .avatar {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }
}

.menu-popup {
    font-size: 14px;
    .van-icon,
    a {
        display: block;
        line-height: 50px;
        margin: 0 16px;
        border-bottom: 1px solid #eee;
    }
}

@media screen and (max-width: $center-width) and (min-width: 640px) {
    .header {
        .container {
            min-width: 720px;
        }
    }
}

@media screen and (max-width: 720px) {
    .header {
        display: none;
    }
    .mobile-header {
        display: flex;
    }
}
</style>
